import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
// import JwtService from "@/core/services/jwt.service";
// import axios from "axios";

// action types
export const UPDATE_TABLE_STRUCTURE = "getWAREHOUSEBASICTableStructure";
export const UPDATE_TABLE_DATA = "getWAREHOUSEBASICTableData";
export const EXPORT_TABLE_DATA = "exportWAREHOUSEBASICTableData";

// mutation types
export const SET_TABLE_STRUCTURE = "setWAREHOUSEBASICTableStructure";
export const SET_TABLE_DATA = "setWAREHOUSEBASICTableData";
export const SET_TABLE_FILTER = "setWAREHOUSEBASICTableFilter";
export const SET_TABLE_PAGE = "setWAREHOUSEBASICTablePage";
export const SET_TABLE_PERPAGE = "setWAREHOUSEBASICTablePerPage";
export const SET_TABLE_SORTBY = "setWAREHOUSEBASICTableSortBy";
export const SET_TABLE_SORTORDER = "setWAREHOUSEBASICTableSortOrder";
export const SET_EXPORT_LINK = "setWAREHOUSEBASICExportLink";

const state = {
  export_link: null,
  table_structure: {},
  table_data: {},
  table_state: {
    page: 1,
    per_page: 50,
    sortBy: "id",
    sortOrder: "desc",
    filters: [],
  },
};

const getters = {
  /**
   * for table title, descripton
   * */
  getWAREHOUSEBASICTableProperties(state) {
    return state.table_structure.properties;
  },
  /**
   * for table permissions
   **/
  getWAREHOUSEBASICTablePermissions(state) {
    return state.table_structure.permissions;
  },
  getWAREHOUSEBASICTableState(state) {
    // console.log("table state is ", state.table_state);
    return state.table_state;
  },

  getWAREHOUSEBASICTableHeaders(state) {
    return state.table_structure.columns;
  },

  getWAREHOUSEBASICTableFilters(state) {
    return state.table_structure.filters;
  },

  getWAREHOUSEBASICTableData(state) {
    return state.table_data.data;
  },

  getWAREHOUSEBASICExportUrl(state) {
    return state.export_link;
  },
};

const actions = {
  [UPDATE_TABLE_STRUCTURE](context) {
    // console.log(payload);
    return new Promise((resolve) => {
      ApiService.post("/warehouse_management/warehouse_basic")
        .then(({ data }) => {
          // console.log("suc", data);
          context.commit(SET_TABLE_STRUCTURE, data);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [UPDATE_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      // ApiService.post("login", credentials)
      ApiService.post(
        "/warehouse_management/warehouse_basic/datatable",
        payload
      )
        .then(({ data }) => {
          // console.log("data of datatable ", data);
          context.commit(SET_TABLE_DATA, data);
          resolve(data);

          context.commit(SET_PAGE_LOADING, false);
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [EXPORT_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/warehouse_management/warehouse_basic/export", payload)
        .then(({ data }) => {
          // console.log("export data is ", data);
          context.commit(SET_EXPORT_LINK, data.url);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
};

const mutations = {
  [SET_TABLE_FILTER](state, filter) {
    state.table_state.filters = filter;
  },
  [SET_TABLE_PAGE](state, page) {
    state.table_state.page = page;
  },
  [SET_TABLE_PERPAGE](state, perPage) {
    state.table_state.per_page = perPage;
  },
  [SET_TABLE_SORTBY](state, sortBy) {
    state.table_state.sortBy = sortBy;
  },
  [SET_TABLE_SORTORDER](state, sortOrder) {
    state.table_state.sortOrder = sortOrder;
  },
  [SET_TABLE_STRUCTURE](state, table_structure) {
    state.table_structure = table_structure.data;
  },
  [SET_TABLE_DATA](state, table_data) {
    state.table_data = table_data;
  },
  [SET_EXPORT_LINK](state, link) {
    state.export_link = link;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
