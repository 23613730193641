<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    max-width="600px"
    style="background-color: white !important; height: 80vh !important"
  >
    <div class="bg-white poppins modal-root">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Create</h3>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>

      <div class="d-flex flex-column mx-5 modal-body">
        <!--begin::Body-->
        <div>
          <v-text-field
            class
            v-model="formData.title"
            label="Title"
            clearable
            outlined
            dense
            :error-messages="titleErrors"
            @input="$v.formData.title.$touch()"
            @blur="$v.formData.title.$touch()"
          ></v-text-field>
          <v-text-field
            class
            v-model="formData.iso_2"
            label="ISO 2"
            clearable
            outlined
            dense
            :error-messages="iso_2Errors"
            @input="$v.formData.iso_2.$touch()"
            @blur="$v.formData.iso_2.$touch()"
          ></v-text-field>
          <v-text-field
            class
            v-model="formData.iso_3"
            label="ISO 3"
            clearable
            outlined
            dense
            :error-messages="iso_3Errors"
            @input="$v.formData.iso_3.$touch()"
            @blur="$v.formData.iso_3.$touch()"
          ></v-text-field>
        </div>

        <div class="d-flex align-end modal-action flex-wrap pt-4">
          <button
            type="submit"
            class="btn btn-danger px-5 py-3 mx-2"
            @click="toggleModal"
          >
            Close
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 mx-2"
            @click="submitEditForm"
          >
            Create
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
// const greaterThanZero = (value) => value > 0;

export default {
  name: "CreateLocation",
  mixins: [validationMixin],
  props: {
    locationId: {
      required: true,
    },
    locationType: {
      required: true,
    },
    locationFunction: {
      required: true,
    },
    locationSetValue: {
      required: true,
      type: Function,
    },
    initValue: {
      required: false,
      default: "",
      type: String,
    },
  },

  validations() {
    return {
      formData: {
        title: { required },
        iso_2: { required },
        iso_3: { required },
      },
    };
  },
  data: () => ({
    dialog: false,
    isCreated: false,
    formData: {
      title: null,
      iso_2: null,
      iso_3: null,
    },
  }),
  methods: {
    handleFormValidation(fieldName, vueObj, dataName = "formData") {
      const errors = [];
      if (!vueObj.$v[`${dataName}`][fieldName].$dirty) return errors;
      if ("required" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].required &&
          errors.push("This field is required");
      }
      if ("maxValue" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].maxValue &&
          errors.push("This field must be greater than 0");
      }
      if ("url" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
    async submitEditForm() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      this.$store.commit(SET_PAGE_LOADING, true);
      const parents = {
        state: "country_id",
        city: "state_id",
        area: "city_id",
      };
      const urls = {
        state: "/order_management/pending_audit_orders/add_state",
        city: "/order_management/pending_audit_orders/add_city",
        area: "/order_management/pending_audit_orders/add_area",
      };
      let id_type = false;
      if (this.locationType != "state") {
        id_type = true;
      }
      let data = { ...this.formData };
      data[`${parents[`${this.locationType}`]}`] = this.locationId;
      data["type"] = this.locationType;
      // console.log(data, parents, urls);
      await ApiService.post(urls[`${this.locationType}`], data)
        .then(async () => {
          Swal.fire({
            title: "Created",
            text: `Creation succeed!`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          const data = this.formData.title;
          // this.locationSetValue(this.locationType, data);
          this.isCreated = true;

          await this.locationFunction(this.locationId, false, id_type);
          await this.$nextTick(async () => {
            await this.locationSetValue(this.locationType, data);
          });
          this.toggleModal();
        })
        .catch(() => {})
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    resetForm() {
      this.$v.$reset();
      this.isCreated = false;
      this.formData = {
        title: null,
        iso_2: null,
        iso_3: null,
      };
    },
    toggleModal() {
      if (this.dialog) {
        if (!this.isCreated) {
          this.locationSetValue(this.locationType);
        }
        this.resetForm();
      } else {
        this.formData.title = this.initValue;
      }
      this.dialog = !this.dialog;
    },
  },
  computed: {
    titleErrors: function () {
      return this.handleFormValidation("title", this);
    },
    iso_2Errors: function () {
      return this.handleFormValidation("iso_2", this);
    },
    iso_3Errors: function () {
      return this.handleFormValidation("iso_3", this);
    },
  },
};
</script>
